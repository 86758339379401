<script setup>
const {
  public: { yandexMetrikaId },
} = useRuntimeConfig();

onMounted(() => {
  (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
    m[i].l = 1 * new Date();
    for (var j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
  })
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(yandexMetrikaId, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  });

  // Получаем все параметры из URL
  const params = new URLSearchParams(window.location.search);
  const utmParams = {};

  // Проходим по всем параметрам и добавляем их в объект
  for (const [key, value] of params.entries()) {
    // Фильтр UTM-меток
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  }

  // Если есть UTM-метки, отправляем их в Яндекс.Метрику
  if (Object.keys(utmParams).length > 0) {
    ym(yandexMetrikaId, 'params', utmParams);
  }
});
</script>

<template>
<div>
  <img :src="`https://mc.yandex.ru/watch/${yandexMetrikaId}`" style="position:absolute; left:-9999px;" alt="" />
</div>
</template>
