const API = useFavoritesApi();

/**
 * Хранилище избранных активов.
 */
export const useFavoriteStore = defineStore('favorites', {
  state: () => ({
    favorites: [], // список ID избранных активов
    isLoaded: false, // флаг инициализации
  }),
  getters: {
    /**
     * Находится ли актив в избранном?
     * @param {object} state - состояние хранилища.
     * @param {string} assetId - ID актива.
     * @returns {boolean} - да / нет.
     */
    isFavorite: (state) => {
      return (assetId) => state.favorites.includes(assetId);
    },
  },
  actions: {
    /**
     * Запрашивает список избранных активов из API.
     * @returns {void}
     */
    async fetchFavorites() {
      if (this.isLoaded) {
        return;
      }

      const { data: response, status, error } = await API.getFavorites();

      if (status.value === 'success') {
        this.favorites = response.value.favorites;
        this.isLoaded = true;
      } else {
        console.log('При запросе избранных активов API вернул ошибку', error);
      }
    },
    /**
     * Добавляет актив список избранных.
     * @param {string} assetId - ID актива.
     */
    async addFavorite(assetId) {
      const { status, error } = await API.addFavorite(assetId);

      if (status.value === 'success') {
        this.favorites.push(assetId);
      } else {
        console.log('При добавления актива в избранное API вернул ошибку', error);
      }
    },
    /**
     * Удаляет актив из списка избранных.
     * @param {string} assetId - ID актива.
     * @returns {void}
     */
    async deleteFavorite(assetId) {
      const index = this.favorites.indexOf(assetId);
      if (index === -1) {
        console.log(`ID '${assetId}' актива не найден среди избранных!`);
        return;
      }

      const { status, error } = await API.deleteFavorite(assetId);

      if (status.value === 'success') {
        this.favorites.splice(index, 1);
      } else {
        console.log('При удалении актива из избранного API вернул ошибку', error);
      }
    },
  },
});
