<template lang="pug">
span.ui-loader(:class="classes")
  .ui-loader__item
</template>

<script>
export default {
  props: {
    white: { type: Boolean, default: false },
    black: { type: Boolean, default: false },
  },

  computed: {
    classes() {
      let classes = {
        'ui-loader-white': this.white,
        'ui-loader-black': this.black,
      };

      return classes;
    },
  },
};
</script>

<style lang="scss">
.ui-loader {
  &-white &__item,
  &-white &__item::before,
  &-white &__item::after {
    background-color: white;
  }
  &-black &__item,
  &-black &__item::before,
  &-black &__item::after {
    background-color: $gray100;
  }

  &__item {
    margin: 0 auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    animation: loading 0.5s infinite linear alternate;
    animation-delay: 0.25s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      animation: loading 0.5s infinite alternate;
      animation-delay: 0s;
      top: 0;
      left: -16px;
    }

    &::after {
      left: 16px;
      top: 0;
      animation-delay: 0.5s;
    }
  }

  @keyframes loading {
    0% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0.2;
    }
  }
}
</style>
