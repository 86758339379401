/** Стор для хранения данных пользователя */
export const useUserStore = defineStore('user-store', {
  state: () => ({
    personalData: {}, // персональные данные пользователя
  }),
  actions: {
    /**
     * Проверяет авторизован ли пользователь.
     * @returns {boolean} - да / нет.
     */
    isSignIn() {
      const jwtStore = useJwtStore();
      return jwtStore.isExists() && !jwtStore.isExpired();
    },
    /**
     * Авторизует пользователя.
     * @param {string} token - JWT токен.
     * @param {string} refreshToken - JWT рефреш-токен.
     */
    signIn(token, refreshToken) {
      const jwtStore = useJwtStore();
      jwtStore.setToken(token);
      jwtStore.setRefreshToken(refreshToken);
    },
    /**
     * Удаляет токен авторизации.
     */
    signOut() {
      const jwtStore = useJwtStore();
      jwtStore.clear();
    },
    /**
     * Проверяет, является ли пользователь админом
     */
    isAdmin() {
      return this.personalData.hasOwnProperty('role') && this.personalData.role === 'admin';
    },
    /**
     * Подтверждает согласие с политикой о неразглашении конфиденциальной информации.
     */
    confirmConfInfoAgreement() {
      if (this.personalData.hasOwnProperty('conf_info_agreement')) {
        this.personalData.conf_info_agreement = true;
      }
    },
    /**
     * Загружает данные текущего пользователя из API.
     * @returns {void}
     */
    async fetchPersonalData() {
      if (Object.keys(this.personalData).length > 0) {
        return;
      }

      const userApi = useUserApi();
      const { data: response, error } = await userApi.getPersonalData();

      if (error.value) {
        console.log(`Не удалось получить данные пользователя!`);
        return;
      }

      this.personalData = response.value;
    },
  },
});
