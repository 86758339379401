// Миддлвар для загрузки избранных активов
export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const favoriteStore = useFavoriteStore();

  // загружаем избранные, если пользователь авторизован
  if (userStore.isSignIn()) {
    await favoriteStore.fetchFavorites();
  }
});
