const API = useApi();

// eslint-disable-next-line require-jsdoc
export const useFiltersApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    getFilters(kind = null) {
      return API.Get(`/filters`, { kind: kind });
    },
  };
};
