/**
 * Фильтрует узел дерева и его дочерние элементы по подстроке.
 * @param {object} leaf - узел дерева
 * @param {string} substr - подстрока для поиска
 * @returns {object | null} - отфильтрованный узел | null
 */
// eslint-disable-next-line complexity, require-jsdoc
const filterLeafBySubstring = (leaf, substr) => {
  // проверяем, является ли текущий узел дерева нужным
  if (leaf.title.toLowerCase().includes(substr.toLowerCase())) {
    // если да, возвращаем его
    return leaf;
  }

  // если текущий узел не является нужным, проверяем его дочерние узлы
  if (leaf.leaf === false && leaf.subkinds.length > 0) {
    // создаем новый массив для отфильтрованных дочерних узлов
    const filteredSubkinds = [];

    // рекурсивно вызываем функцию sortLeafBySubstring для каждого дочернего узла
    leaf.subkinds.forEach((subkind) => {
      const filteredSubkind = filterLeafBySubstring(subkind, substr);

      // если узел прошел фильтрацию, добавляем его в отфильтрованный массив
      if (filteredSubkind) {
        filteredSubkinds.push(filteredSubkind);
      }
    });

    // если есть отфильтрованные дочерние узлы, возвращаем узел с этими узлами
    if (filteredSubkinds.length > 0) {
      return { ...leaf, subkinds: filteredSubkinds };
    }
  }

  // если текущий узел и его дочерние узлы не прошли фильтрацию, возвращаем null
  return null;
};

/**
 * Рекурсивно ищет в переданном узле дерева ноду с переданным классом.
 * @param {object} leaf - узел дерева
 * @param {*} className - мнемонический класс категории
 * @returns
 */
// eslint-disable-next-line complexity, require-jsdoc
const findLeafByClassName = (leaf, className) => {
  // проверяем, является ли текущий узел дерева нужным
  if (leaf.class_name === className) {
    // если да, возвращаем его
    return leaf;
  }

  // если текущий узел не является нужным, проверяем его дочерние узлы
  if (leaf.leaf === false && leaf.subkinds.length > 0) {
    for (const child of leaf.subkinds) {
      // рекурсивно ищем в потомках
      const found = findLeafByClassName(child, className);

      // если нашли - возвращаем потомка
      if (found) {
        return found;
      }
    }
  }

  // если ничего не нашли
  return null;
};

// eslint-disable-next-line require-jsdoc
export const useTreeFilter = () => {
  return {
    /**
     * Фильтрует дерево категорий по строке поиска.
     * @param {Array} tree - дерево категорий
     * @param {string} substr - строка поиска
     * @returns {Array} - отфильтрованное дерево
     */
    filterBySubstring(tree, substr) {
      const filteredLeafs = [];

      tree.forEach((leaf) => {
        let filteredLeaf = filterLeafBySubstring(leaf, substr);
        if (filteredLeaf) {
          filteredLeafs.push(filteredLeaf);
        }
      });

      return filteredLeafs;
    },

    /**
     * Ищет в дереве категорию с переданным классом.
     * @param {Array} tree - дерево категорий
     * @param {string} className - класс категории
     * @returns {object | null} - найденная категория | null
     */
    findByClassName(tree, className) {
      console.log('tree', tree);
      for (const leaf of tree) {
        const found = findLeafByClassName(leaf, className);
        if (found) {
          return found;
        }
      }

      return null;
    },
  };
};
