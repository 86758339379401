/**
 * Хелпер для обработки query параметров для фильтрации активов.
 * @returns {object} - объект с функциями.
 */
export const useFiltersSerializer = () => {
  /**
   * Приводит фильтры к query параметрам.
   * @param {object} filters - фильтры.
   * @returns {object} - объект с query параметрами.
   */
  function toQueryParams(filters) {
    const queryParams = {};

    for (const name of Object.keys(filters)) {
      const filter = filters[name];
      if (!filter.value) {
        continue;
      }

      // если фильтр - диапазон значений
      if (filter.type === 'range') {
        if (filter.value && filter.value.min) {
          queryParams[`${name}_min`] = filter.value.min;
        }
        if (filter.value && filter.value.max) {
          queryParams[`${name}_max`] = filter.value.max;
        }
        // другие типы фильтров
      } else {
        queryParams[name] = filter.value;
      }
    }

    return queryParams;
  }
  /**
   * Приводит фильтры к поисковому запросу.
   * @param {object} filters - фильтры.
   * @returns {object} - объект с параметрами для поиска в API.
   */
  function toSearchParams(filters) {
    const searchParams = [];
    const rangeFilters = {};

    // eslint-disable-next-line complexity
    // Проходим в цикле по всем фильтрам
    for (const name of Object.keys(filters)) {
      const filter = filters[name];
      if (!filter.value) {
        continue;
      }

      // если фильтр - диапазон значений
      if (filter.type === 'range') {
        rangeFilters[name] = {};

        if (filter.value.min) {
          rangeFilters[name].min = filter.value.min;
        }
        if (filter.value.max) {
          rangeFilters[name].max = filter.value.max;
        }
      }
      // другие типы фильтров
      else {
        // если фильтр - список значений
        if (Array.isArray(filter.value)) {
          searchParams.push({ field: name, values: filter.value });
        }
        // если фильтр - единичное значений
        else {
          searchParams.push({ field: name, value: filter.value });
        }
      }
    }

    // Обходим циклом все диапазоны
    for (const name of Object.keys(rangeFilters)) {
      const filter = rangeFilters[name];
      const searchParam = {
        field: name,
      };
      if (filter.min) {
        searchParam.min = filter.min;
      }
      if (filter.max) {
        searchParam.max = filter.max;
      }

      searchParams.push(searchParam);
    }

    return searchParams;
  }
  /**
   * Приводит фильтры к объектам для бейджиков.
   * @param {Array} regions - регионы.
   * @param {object} filters - фильтры.
   * @returns {object} - объект с параметрами для отрисовки бейджей.
   */
  function toBadgesParams(regions, filters) {
    const result = [];

    // добавляем бейдж с регионами
    if (regions.length) {
      result.push({
        name: 'regions',
        description: getRegionsBadgeDescription(regions),
      });
    }

    // добавляем бейджи с фильтрами
    for (const name of Object.keys(filters)) {
      const filter = filters[name];
      let description = '';

      if (filter.type === 'range') {
        description = getRangeBadgeDescription(filter);
      } else if (['radio', 'inline-radio', 'inline-radio-chip'].includes(filter.type)) {
        description = getRadioBadgeDescription(filter);
      } else if (['checkbox', 'inline-checkbox-chip'].includes(filter.type)) {
        description = getCheckboxBadgeDescription(filter);
      } else if (filter.type === 'suggestion') {
        description = getInputBadgeDescription(filter);
      } else {
        continue;
      }

      result.push({
        name: filter.name,
        description: description,
      });
    }

    return result;
  }

  /**
   * Возвращает описание для бейджа фильтра с диапазоном значений.
   * @param {object} filter - объект с данными фильтра.
   * @returns {string} - описание.
   */
  function getRangeBadgeDescription(filter) {
    const description = [];

    if (filter.value.min) {
      description.push(filter.value.min);
    }
    if (filter.value.min && filter.value.min) {
      description.push('–');
    }
    if (filter.value.max) {
      description.push(filter.value.max);
    }
    if (filter.measure) {
      description.push(filter.measure);
    }

    return description.join(' ');
  }

  /**
   * Возвращает описание для бейджа фильтра с типом radio.
   * @param {object} filter - объект с данными фильтра.
   * @returns {string} - описание.
   */
  function getRadioBadgeDescription(filter) {
    const description = [];

    // добавляем лейбл фильтра
    description.push(`${filter.label}:`);

    // берем значение
    let key = filter.value;
    let label = key;

    // ищем перевод значения среди опций фильтра
    const foundOption = filter.options.find((o) => o.key === key);
    if (foundOption && foundOption.label) {
      label = foundOption.label;
    }

    description.push(label.toLowerCase());

    return description.join(' ');
  }

  /**
   * Возвращает описание для бейджа фильтра с типом checkbox.
   * @param {object} filter - объект с данными фильтра.
   * @returns {string} - описание.
   */
  function getCheckboxBadgeDescription(filter) {
    const description = [];
    let key = filter.value[0];
    let label = key;

    // ищем перевод значения среди опций фильтра
    if (filter.options && filter.options.length) {
      const foundOption = filter.options.find((o) => o.key === key);
      if (foundOption && foundOption.label) {
        label = foundOption.label;
      }
    }

    description.push(label);

    // если выбрано больше одной опции - добавляем остаток
    if (filter.value.length > 1) {
      const remains = filter.value.length - 1;
      description.push(`+ ${remains}`);
    }

    return description.join(' ');
  }

  /**
   * Возвращает описание для бейджа фильтра с типом input.
   * @param {object} filter - объект с данными фильтра.
   * @returns {string} - описание.
   */
  function getInputBadgeDescription(filter) {
    return filter.value;
  }

  /**
   * Возвращает описание для бейджа фильтра по регионам.
   * @param {Array} regions - список регионов.
   * @returns {string} - описание.
   */
  function getRegionsBadgeDescription(regions) {
    const description = [];
    description.push(regions[0]);
    if (regions.length > 1) {
      const estimate = regions.length - 1;
      description.push(`+${estimate}`);
    }

    return description.join(' ');
  }

  return { toQueryParams, toSearchParams, toBadgesParams };
};
