const API = useApi();
const idempotencyKey = useIdempotencyKey();

// eslint-disable-next-line require-jsdoc
export const useAuthApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    smsDeliver(phone) {
      return API.Post(`/auth/sms/deliver`, { sms: { phone: phone } });
    },

    // eslint-disable-next-line require-jsdoc
    smsVerify(phone, code) {
      return API.Post(`/auth/sms/verify`, { sms: { sms_code: code, phone: phone } });
    },

    // eslint-disable-next-line require-jsdoc
    userCheckExists(phone) {
      return API.Get(`/auth/users/exists`, { phone: phone });
    },

    // eslint-disable-next-line require-jsdoc
    confirmConfInfoAgreement() {
      return API.Post(`/auth/users/confirm_conf_info_agreement`);
    },

    // eslint-disable-next-line require-jsdoc
    loginUser(phone, code) {
      return API.Post(`/auth/login`, { login: { sms_code: code, phone: phone } });
    },

    // eslint-disable-next-line require-jsdoc
    checkUserData(user) {
      return API.Post(`/auth/users/validate`, { user: user });
    },

    // eslint-disable-next-line require-jsdoc
    registerUser(user) {
      return API.Post(`/auth/registration`, { registration: user });
    },

    // eslint-disable-next-line require-jsdoc
    refreshJwtToken(refreshToken) {
      const key = idempotencyKey.generate(refreshToken, 30);

      return API.Post(`/auth/jwt/refresh`, { refresh_token: refreshToken, idempotency_key: key });
    },

    // eslint-disable-next-line require-jsdoc
    logout() {
      return API.Delete(`/auth/logout`);
    },
  };
};
