// Миддлвар для загрузки данных фильтров
export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();

  if (!userStore.isSignIn()) {
    return;
  }

  if (from.name === 'kinds-category' || to.name === 'kinds-category') {
    const filtersStore = useFiltersStore();
    const selectedFiltersStore = useSelectedFiltersStore();

    // если пользователь переходит на страницу каталога
    if (to.name === 'kinds-category') {
      // если SSR
      if (process.server) {
        // инициализируем фильтры из query параметров
        await filtersStore.initializeByQuery();

        // задаем значения выбранных фильтров
        selectedFiltersStore.setKind(filtersStore.kind);
        selectedFiltersStore.setRegions(filtersStore.regions);
        selectedFiltersStore.setFilters(filtersStore.filters);
      }
      // если на клиенте
      else {
        // задаем категорию по-умолчанию в выбранных фильтрах
        if (!selectedFiltersStore.kind) {
          selectedFiltersStore.setKind(to.params.category);
        }
      }
      // если пользователь уходит со страницы каталога - чистим фильтры
    } else {
      filtersStore.clearKind();
      filtersStore.clearFilters();
      selectedFiltersStore.clearKind();
      selectedFiltersStore.clearFilters();
    }
  }
});
