// API для получения категорий
const API = useAssetsApi();
// сортировщик дерева категорий
const treeFilter = useTreeFilter();

/** Стор для хранения данных JWT-токена */
export const useKindTreeStore = defineStore('kind-tree', {
  state: () => ({
    tree: [],
  }),
  getters: {
    /**
     * Возвращает объект категории по мнемоническому имени.
     * @param {object} state - состояние.
     * @param {string} className - имя категории.
     * @returns {object | boolean} - объект категории | false
     */
    kindByClass(state) {
      return (className) => {
        const leaf = treeFilter.findByClassName(state.tree, className);
        if (leaf !== null) {
          return leaf;
        }

        return false;
      };
    },

    /**
     * Возвращает родительский объект категории по мнемоническому имени потомка.
     * @param {object} state - состояние.
     * @param {string} className - имя категории.
     * @returns {object | boolean} - объект категории | false
     */
    rootByClass(state) {
      return (className) => {
        let result = false;

        for (const key in state.tree) {
          if (Object.hasOwnProperty.call(state.tree, key)) {
            const root = state.tree[key];
            const child = treeFilter.findByClassName(root.subkinds, className);

            if (child) {
              result = root;
              break;
            }
          }
        }

        return result;
      };
    },
  },
  actions: {
    /**
     * Инициализирует дерево категорий из ответа API.
     */
    async fetchTree() {
      if (this.tree.length > 0) {
        return;
      }

      const { data: response, status, error } = await API.getKindsTree();
      if (error.value) {
        console.log('При запросе дерева категорий API вернул ошибку', error.value);
      } else if (status.value !== 'success') {
        console.log('При запросе дерева категорий API вернул некорректные данные');
      } else {
        this.tree = response.value.tree;
      }
    },
  },
});
