const API = useApi();

// eslint-disable-next-line require-jsdoc
export const useUserApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    getPersonalData() {
      return API.Get(`/users/personal_data`, {});
    },
  };
};
