export default {
  isProduction() {
    const {
      public: { frontEnv },
    } = useRuntimeConfig();

    return frontEnv === 'production';
  },

  isStaging() {
    const {
      public: { frontEnv },
    } = useRuntimeConfig();

    return frontEnv === 'staging';
  },

  isDevelop() {
    const {
      public: { frontEnv },
    } = useRuntimeConfig();

    return frontEnv === 'development';
  },
};
