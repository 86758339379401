/**
 * Ключ идемпотентности.
 * @returns {object} - объект с функциями.
 */
export const useIdempotencyKey = () => {
  return {
    /**
     * Генерирует ключ идемпотентности.
     * @param {string} payload - дополнительные данные для уникальности ключа.
     * @param {integer} seconds - сколько секунд будет одинаковый ключ: если 0 - уникальный при каждом вызове, если меньше нуля - всегда одинаковый.
     * @returns {string} - ключ идемпотентности.
     */
    generate(payload = '', seconds = 5) {
      const userStore = useUserStore();
      let timeRange;

      if (seconds === 0) {
        timeRange = Date.now() + Math.random();
      } else if (seconds > 0) {
        timeRange = Math.floor(Date.now() / (seconds * 1000));
      } else {
        timeRange = 0;
      }

      return `${userStore.personalData.external_id}_${payload}_${timeRange}`;
    },
  };
};
