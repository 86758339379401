<script setup>
const props = defineProps({
  error: Object,
});

let titleText = 'Страница ошибки';

switch (props.error.statusCode) {
  case 401:
    titleText = 'Необходима авторизация';
    break;
  case 403:
    titleText = 'Доступ запрещен';
    break;
  case 404:
    titleText = 'Страница не найдена';
    break;

  default:
    titleText = 'Ошибка сервера';
    break;
}

useHead({
  title: titleText,
});

// eslint-disable-next-line require-jsdoc
const handleNotFound = () => {
  clearError({
    redirect: '/catalog',
  });
};

// eslint-disable-next-line require-jsdoc
const handleNotAuthorize = () => {
  clearError({
    redirect: '/',
  });
};

// eslint-disable-next-line require-jsdoc
const handleForbidden = () => {
  clearError({
    redirect: '/catalog',
  });
};

// eslint-disable-next-line require-jsdoc
const handleDefault = () => {
  clearError({
    redirect: '/catalog',
  });
};
</script>

<template lang="pug">
.error-page
  img.error-page__logo(src="@/assets/images/logo.svg")

  .error-page__content
    template(v-if="error.statusCode === 401")
      h2 Необходима авторизация!
      ui-btn(secondary small @click="navigateTo('/')") На главную

    template(v-else-if="error.statusCode === 403")
      h2 Доступ запрещен!
      ui-btn(secondary small @click="navigateTo('/catalog')") На главную

    template(v-else-if="error.statusCode === 404")
      h2 Страница не найдена
      p Неправильно набран адрес, или такой страницы больше не существует.
      ui-btn(secondary small @click="navigateTo('/catalog')") На главную

    template(v-else)
      h2 Ошибка сервера
      p(v-if="environment.isDevelop()") {{ error.message }}
      p Попробуйте перезагрузить страницу или перейти на главную. Если не поможет, зайдите позже.
      ui-btn(secondary small @click="navigateTo('/catalog')") На главную
</template>

<style lang="scss">
.error-page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(187.94% 140.62% at 0% 0%, #333f48 0%, #283038 52.4%, #22262a 100%);

  &__logo {
    position: fixed;
    width: 196px;
    left: 40px;
    top: 16px;
  }

  &__content {
    color: $gray10;

    h2 {
      font-size: 56px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -84px;
        width: 64px;
        height: 2px;
        background: $lime40;
        transform: translateY(-50%);
      }
    }
    p {
      margin: 16px 0 34px;
      max-width: 388px;
      color: $gray40;
    }
    button.ui-btn.btn-secondary {
      color: $gray10;
      border: 1px solid rgba(255, 255, 255, 0.2);

      &:hover {
        color: $gray100;
      }
    }
  }
}

// MEDIA 320-1200
@media screen and (min-width: $mobile-min) and (max-width: $tablet-large-max) {
  .error-page {
    padding: 16px;

    &__logo {
      left: 16px;
    }

    &__content {
      text-align: center;

      h2 {
        font-size: 32px;
        position: static;
      }

      p {
        margin: 12px auto 24px;
        max-width: 80%;
        font-size: 16px;
      }
      button.ui-btn.btn-secondary {
        margin: 0 auto;
        width: 137px;
      }
    }
  }
}
</style>
