const filtersSerializer = useFiltersSerializer();
const assetsAPI = useAssetsApi();

/** Стор для хранения выбранных фильтров в форме */
export const useSelectedFiltersStore = defineStore('selected-filters', {
  state: () => ({
    kind: '',
    regions: [],
    filters: {},
  }),

  getters: {
    /**
     * Есть ли выбранные фильтры?
     * @param {object} state - состояние хранилища.
     * @returns {boolean} - да / нет.
     */
    filtersExists(state) {
      return Object.keys(state.filters).length > 0;
    },
    /**
     * Возвращает значение фильтра по имени.
     * @param {string} name - имя фильтра.
     * @param {object} state - состояние хранилища.
     * @returns {object | string | null} - значение фильтра.
     */
    filterValue: (state) => {
      return (name) => (state.filters[name] ? state.filters[name].value : undefined);
    },
    /**
     * Приводит текущие фильтры к массиву с объектам параметров для поиска активов в API.
     * @param {object} state - состояние хранилища.
     * @returns {Array} - массив с объектам параметров для поиска
     */
    searchParams(state) {
      return filtersSerializer.toSearchParams(state.filters);
    },
    /**
     * Хэш-сумма всех фильтров.
     * @param {object} state - состояние хранилища.
     * @returns {string} - хэш-сумма.
     */
    hashSum(state) {
      return `${state.kind}${state.regions.toString()}${JSON.stringify(state.filters)}`;
    },
  },
  actions: {
    /**
     * Задает категорию активов.
     * @param {string} new_kind - мнемоническое имя категории
     */
    setKind(new_kind) {
      this.kind = new_kind;
    },
    /**
     * Задает список регионов для фильтрации.
     * @param {Array} regions - список регионов.
     */
    setRegions(regions) {
      this.regions = regions;
    },
    /**
     * Добавляет регион в список.
     * @param {string} region - регион или город.
     */
    addRegion(region) {
      if (!this.regions.includes(region)) {
        this.regions.push(region);
      }
    },
    /**
     * Убирает регион из списка.
     * @param {string} region - регион или город.
     */
    removeRegion(region) {
      if (this.regions.includes(region)) {
        this.regions = this.regions.filter((item) => item !== region);
      }
    },
    /**
     * Очищает список регионов.
     */
    clearRegions() {
      this.regions = [];
    },
    /**
     * Задает новое значение фильтра.
     * @param {object} filter - объект с данными фильтра
     * @param {string | Array | object} value - значение фильтра
     */
    setFilter(filter, value) {
      this.filters[filter.name] = filter;
      this.filters[filter.name].value = value;
    },
    /**
     * Задает набор фильтров.
     * @param {object} filters - объект с данными фильтров.
     */
    setFilters(filters) {
      this.filters = { ...filters };
    },
    /**
     * Удаляет фильтр.
     * @param {string} name - название фильтра.
     */
    removeFilter(name) {
      if (name === 'regions') {
        this.regions = [];
      } else if (this.filters[name]) {
        delete this.filters[name];
      }
    },
    /**
     * Сбрасывает фильтры.
     */
    clearFilters() {
      this.regions = [];
      this.filters = {};
    },
    /**
     * Сбрасывает категорию.
     */
    clearKind() {
      this.kind = undefined;
    },
    /**
     * Возвращает количество активов по заданным фильтрам.
     * @returns {number} - количество активов по заданным в хранилище фильтрам.
     */
    async getAssetsCount() {
      const search = {
        kind: this.kind,
        addresses: this.regions,
        filters: this.searchParams,
      };
      const { data: response, error } = await assetsAPI.getAssetsCount(search);

      if (error.value) {
        console.log(`Не удалось получить количество активов. Ошибка: ${error.message}.`);
        return 0;
      }

      return response.value;
    },
  },
});
