const API = useApi();

// emulate_error_code: 500
// emulate_timeout
// eslint-disable-next-line require-jsdoc
export const useAssetsApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    getAssets(searchParams) {
      return API.Post(`/assets`, { search: searchParams });
    },

    // eslint-disable-next-line require-jsdoc
    getAssetsCount(searchParam) {
      return API.Post(`/assets/count`, { search: searchParam });
    },

    // eslint-disable-next-line require-jsdoc
    getFavoritesAssets(searchParams = {}) {
      return API.Post(`/assets/favorites`, { search: searchParams });
    },

    // eslint-disable-next-line require-jsdoc
    getNewAssets() {
      return this.getAssets({
        per: 4,
        sort: 'created_at_desc',
      });
    },

    // eslint-disable-next-line require-jsdoc
    getAsset(id) {
      return API.Get(`/assets/${id}`, {});
    },

    // eslint-disable-next-line require-jsdoc
    getKinds() {
      return API.Get(`/kinds`, {});
    },

    // eslint-disable-next-line require-jsdoc
    getKindsTree() {
      return API.Get(`/kinds/tree`);
    },
  };
};
