/**
 * Базовый компонент для запросов к API.
 * @returns {object} - объект с функциями.
 */
export const useApi = () => {
  /**
   * Выполняет HTTP POST-запрос по указанному пути с переданными данными.
   * @param {string} path - Путь к ресурсу, на который будет выполнен POST-запрос.
   * @param {object} [data] - Данные, которые будут отправлены в теле запроса (в формате JSON).
   * @returns {Promise<Response>} Промис, который разрешается в объект Response после завершения запроса.
   * @example
   * const postData = { name: 'John', age: 30 };
   * const response = await Post('/api/users', postData);
   * const result = await response.json();
   * console.log(result); // Результат POST-запроса будет выведен в консоль.
   */
  async function Post(path, data = {}) {
    const config = useRuntimeConfig();
    const headers = getAuthHeaders();
    const baseUrl = config.public.apiBaseUrl;

    const result = await useFetch(`${baseUrl}${path}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: headers,
      credentials: 'include',
    });

    const { error, status } = result;
    if (status.value === 'success') {
      return result;
    } else {
      return handleError(error, result);
    }
  }

  /**
   * Выполняет HTTP GET-запрос по указанному пути с переданными параметрами запроса.
   * @param {string} path - Путь к ресурсу, на который будет выполнен GET-запрос.
   * @param {object} [query] - Параметры запроса, передаваемые в строке запроса.
   * @returns {Promise<Response>} Промис, который разрешается в объект Response после завершения запроса.
   * @example
   * const queryParams = { page: 1, limit: 10 };
   * const response = await Get('/api/users', queryParams);
   * const result = await response.json();
   * console.log(result); // Результат GET-запроса будет выведен в консоль.
   */
  async function Get(path, query = {}) {
    const config = useRuntimeConfig();
    const headers = getAuthHeaders();
    const baseUrl = config.public.apiBaseUrl;

    const result = await useFetch(`${baseUrl}${path}`, {
      method: 'GET',
      params: query,
      headers: headers,
      credentials: 'include',
    });

    const { error, status } = result;
    if (status.value === 'success') {
      return result;
    } else {
      return handleError(error, result);
    }
  }

  /**
   * Выполняет HTTP DELETE-запрос по указанному пути с переданными параметрами запроса.
   * @param {string} path - Путь к ресурсу, на который будет выполнен DELETE-запрос.
   * @param {object} data - Данные, которые будут отправлены в теле запроса (в формате JSON).
   * @returns {Promise<Response>} Промис, который разрешается в объект Response после завершения запроса.
   */
  async function Delete(path, data = {}) {
    const config = useRuntimeConfig();
    const headers = getAuthHeaders();
    const baseUrl = config.public.apiBaseUrl;

    const result = await useFetch(`${baseUrl}${path}`, {
      method: 'DELETE',
      body: JSON.stringify(data),
      headers: headers,
      credentials: 'include',
    });

    const { error, status } = result;
    if (status.value === 'success') {
      return result;
    } else {
      return await handleError(error, result);
    }
  }

  /**
   * Собирает заголовки для авторизации.
   * @returns {object} - заголовки.
   */
  function getAuthHeaders() {
    const config = useRuntimeConfig();
    const jwtStore = useJwtStore();
    const basicAuthUser = config.public.basicAuthUser;
    const basicAuthPassword = config.public.basicAuthPassword;
    const headers = {};

    if (jwtStore.token) {
      headers['Authorization'] = `Bearer ${jwtStore.token}`;
    }

    return headers;
  }

  /**
   * Обрабатывает ошибки запросов к API.
   * @param {object} error - объект с ошибкой nuxt.
   * @param {*} result - ответ запроса к API.
   * @returns {object|void} - либо результат запроса, либо выплюнет exception.
   */
  async function handleError(error, result) {
    switch (error.value.statusCode) {
      case 401:
        console.log('API вернул статус 401!');
        throw createError({
          fatal: true,
          statusCode: 401,
          message: 'Необходима авторизация.',
        });
      case 403:
        console.log('API вернул статус 403!');
        throw createError({
          fatal: true,
          statusCode: 403,
          message: 'Доступ запрещен.',
        });
      case 404:
        console.log('API вернул статус 404!');
        return result;
      case 422:
        console.log('API вернул статус 422!');
        return result;
      case 500:
        console.log('API вернул статус 500 (ошибка сервера)!');
        console.log('BODY ', error.value?.data?.message);
        return result;
      case 504:
        console.log('API вернул статус 504 (ошибка ответа портала)!');
        console.log('BODY ', error.value?.data?.message);
        return result;
      default:
        console.log(`API вернул неизвестный статус ${error.value.statusCode}!`);
        console.log('BODY ', error.value?.data?.message);
        return result;
    }
  }

  return { Get, Post, Delete };
};
