const filtersSerializer = useFiltersSerializer();
const filtersApi = useFiltersApi();

/** Стор для хранения фильтров по категориям активов из формы с фильтрами */
export const useFiltersStore = defineStore('filters', {
  state: () => ({
    // категория активов
    kind: undefined,
    // список регионов и городов
    regions: [],
    // объект с подтвержденными фильтрами активов
    filters: {},
    // объект с фильтрами по категориям
    kindFilters: {},
    // параметр сортировки
    sort: undefined,
  }),
  getters: {
    /**
     * Возвращает значение фильтра по имени.
     * @param {object} state - состояние хранилища.
     * @param {string} name - имя фильтра.
     * @returns {object | string | null} - значение фильтра.
     */
    filterValue: (state) => {
      return (name) => (state.filters[name] ? state.filters[name].value : undefined);
    },
    /**
     * Приводит текущие фильтры к массиву с объектам параметров для поиска активов в API.
     * @param {object} state - состояние хранилища.
     * @returns {Array} - массив с объектам параметров для поиска
     */
    searchParams(state) {
      return filtersSerializer.toSearchParams(state.filters);
    },
    /**
     * Приводит текущие фильтры к объекту с query параметрами.
     * @param {object} state - состояние хранилища.
     * @returns {object} - объекту с query параметрами.
     */
    queryParams(state) {
      const params = filtersSerializer.toQueryParams(state.filters);
      if (state.sort) {
        params['sort'] = state.sort;
      }
      if (state.regions) {
        params['regions'] = state.regions;
      }

      return params;
    },
    /**
     * Возвращает массив объектов для бэйджей фильтров.
     * @param {object} state - состояние хранилища.
     * @returns {Array} массив бейджей.
     */
    badges(state) {
      return filtersSerializer.toBadgesParams(state.regions, state.filters);
    },
  },
  actions: {
    /**
     * Задает категорию активов.
     * @param {string} new_kind - мнемоническое имя категории
     */
    setKind(new_kind) {
      this.kind = new_kind;
    },
    /**
     * Задает список регионов для фильтрации.
     * @param {Array} regions - список регионов.
     */
    setRegions(regions) {
      this.regions = regions;
    },
    /**
     * Задает новое значение фильтра.
     * @param {object} filter - объект с данными фильтра
     * @param {string|Array|object} value - значение фильтра
     */
    setFilter(filter, value) {
      this.filters[filter.name] = filter;
      this.filters[filter.name].value = value;
    },
    /**
     * Задает сортировку.
     * @param {string} sort - параметр сортировки.
     */
    setSort(sort) {
      this.sort = sort;
    },
    /**
     * Удаляет фильтр.
     * @param {string} name - название фильтра.
     */
    removeFilter(name) {
      if (name === 'regions') {
        this.regions = [];
      } else if (this.filters[name]) {
        delete this.filters[name];
      }
    },
    /**
     * Применяет выбранные фильтры.
     * @param {string} kind - категория активов.
     * @param {Array} regions - список регионов.
     * @param {object} filters - набор фильтров.
     */
    applySelectedFilters(kind, regions, filters) {
      this.kind = kind;
      this.regions = regions;
      this.filters = { ...filters };
    },
    /**
     * Сбрасывает фильтры.
     */
    clearFilters() {
      this.filters = {};
      this.regions = [];
    },
    /**
     * Сбрасывает категорию.
     */
    clearKind() {
      this.kind = undefined;
    },
    /**
     * Возвращает фильтры для конкретной категории активов.
     * Если их нет - ходит в API и достает значения.
     * @param {string} kind - мнемоническое имя категории.
     * @returns {Array} массив объектов с данными фильтров.
     */
    async getKindFilters(kind) {
      if (this.kindFilters[kind] && this.kindFilters[kind].length > 0) {
        // Достаем данные фильтров категории из кэша
        return this.kindFilters[kind];
      }

      const { data: response, error } = await filtersApi.getFilters(kind);

      if (error.value) {
        console.log(`Не удалось получить список фильтров для категории ${kind}. Ошибка: ${error}.`);
        filters[kind] = [];
      } else {
        // записываем фильтры в кэш
        if (response.value && response.value.filters) {
          this.kindFilters[kind] = response.value.filters;
        } else {
          this.kindFilters[kind] = [];
        }
      }

      return this.kindFilters[kind];
    },
    /**
     * Возвращает параметры сортировки для конкретной категории активов.
     * Если ее нет - ходит в API и достает значение.
     * @param {string} kind - мнемоническое имя категории.
     * @returns {Array} - параметры сортировки.
     */
    async getKindSortOptions(kind) {
      const kindFilters = await this.getKindFilters(kind);
      const sort = kindFilters.find((f) => f.name === 'sort');

      if (!sort || !sort.options) {
        return [];
      }

      return sort.options;
    },
    /**
     * Инициализирует фильтры исходя из текущих query-параметров.
     */
    // eslint-disable-next-line complexity, require-jsdoc
    async initializeByQuery() {
      const route = useRoute();
      const kind = route.params.category;
      const kindFilters = await this.getKindFilters(kind);

      this.clearFilters();
      this.kind = kind;

      if (route.query.sort) {
        this.setSort(route.query.sort);
      }
      if (route.query.regions) {
        this.setRegions(route.query.regions);
      }

      for (const filter of kindFilters) {
        // пропускаем сортировку
        if (filter.type === 'sort') {
          continue;
        }

        // TODO: добавить проверки на вхождение в диапазон и список значений!
        if (filter.type === 'range') {
          const min = route.query[`${filter.name}_min`];
          const max = route.query[`${filter.name}_max`];

          if (min || max) {
            this.filters[filter.name] = filter;
            this.filters[filter.name].value = { min: +min, max: +max };
          }
        } else if (filter.type === 'checkbox' || filter.type === 'inline-checkbox-chip') {
          const value = route.query[filter.name];

          if (value) {
            this.filters[filter.name] = filter;
            this.filters[filter.name].value = Array.isArray(value) ? value : [value];
          }
        } else {
          const value = route.query[filter.name];

          if (value) {
            this.filters[filter.name] = filter;
            this.filters[filter.name].value = value;
          }
        }
      }
    },
  },
});
