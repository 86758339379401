import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();
  if (!sentry.dsn) {
    return;
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.05,
    tracePropagationTargets: ['localhost', 'https://private-staging.portal-da.ru', 'https://private.portal-da.ru'],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  });
});
