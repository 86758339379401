<template lang="pug">
button.ui-btn(:class="classes")
  ui-loader(v-if="loading" black)
  slot(v-else)
</template>

<script>
export default {
  props: {
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    long: { type: Boolean, default: false },
    short: { type: Boolean, default: false },
  },

  computed: {
    /**
     * Генерирует объект классов для кнопки на основе установленных свойств.
     * @function
     * @returns {object} Объект классов для кнопки.
     * @property {boolean} primary - Класс "btn-primary" для основной стилизации кнопки.
     * @property {boolean} secondary - Класс "btn-secondary" для вторичной стилизации кнопки.
     * @property {boolean} transparent - Класс "btn-transparent" для прозрачной кнопки.
     * @property {boolean} disable - Класс "btn-disable" для отключения кнопки.
     * @property {boolean} loading - Класс "btn-loading" для кнопки с состоянием загрузки.
     * @property {boolean} small - Класс "btn-small" для маленькой кнопки.
     * @property {boolean} large - Класс "btn-large" для большой кнопки.
     * @property {boolean} long - Класс "btn-long" для большой удлиненной кнопки.
     * @property {boolean} short - Класс "btn-short" для большой короткой кнопки.
     */
    classes() {
      let classes = {
        'btn-primary': this.primary,
        'btn-secondary': this.secondary,
        'btn-transparent': this.transparent,
        'btn-disable': this.disable,
        'btn-loading': this.loading,
        'btn-small': this.small,
        'btn-large': this.large,
        'btn-long': this.long,
        'btn-short': this.short,
      };

      return classes;
    },
  },
};
</script>

<style lang="scss">
button.ui-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  font-size: 18px;
  line-height: 25px;
  padding: 12px 56px 12px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  // default && sber-btn
  background-color: $gray100;
  border: 1px solid $gray100;
  color: white;

  &:hover {
    background-color: rgba(40, 48, 56, 0.5);
    border: 1px solid rgba(40, 48, 56, 0.5);
  }
  &.btn-disable,
  &.btn-loading {
    background-color: rgba(40, 48, 56, 0.5);
    border: 1px solid rgba(40, 48, 56, 0.5);
    cursor: not-allowed;
  }

  &.btn-primary {
    background-color: $gray80;
    border: 1px solid $gray80;
    color: white;

    &:hover {
      background-color: $gray60;
      border: 1px solid $gray60;
    }
    &.btn-disable,
    &.btn-loading {
      background-color: $gray20;
      border: 1px solid $gray20;
      cursor: not-allowed;
    }
  }

  &.btn-secondary {
    background-color: transparent;
    border: 1px solid $gray20;
    color: $gray100;

    &:hover {
      background-color: $gray10;
      border: 1px solid $gray10;
      color: $gray80;
    }
    &.btn-disable,
    &.btn-loading {
      background-color: $gray20;
      border: 1px solid $gray20;
      color: $gray40;
      cursor: not-allowed;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: white;

    &:hover {
      border: 1px solid $gray40;
    }
  }

  &.btn-large {
    width: 340px;
    padding: 12px 0;
    height: 52px;

    &.btn-long {
      width: 400px;
    }
  }
  &.btn-short {
    padding: 12px 34px;
  }
  &.btn-small {
    min-width: 120px;
    height: 32px;
    min-height: auto;
    padding: 3px 0 4px;

    &.btn-long {
      width: 100%;
    }
  }
}
</style>
