const API = useApi();
const idempotencyKey = useIdempotencyKey();

// eslint-disable-next-line require-jsdoc
export const useFavoritesApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    getFavorites() {
      return API.Get(`/favorites`, {});
    },

    // eslint-disable-next-line require-jsdoc
    addFavorite(id) {
      const key = idempotencyKey.generate(id, 0.5);

      return API.Post(`/favorites`, { asset_id: id, idempotency_key: key });
    },

    // eslint-disable-next-line require-jsdoc
    deleteFavorite(id) {
      return API.Delete(`/favorites/${id}`);
    },
  };
};
