import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import maska_r8BHBbSmHt from "/app/plugins/maska.js";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  maska_r8BHBbSmHt,
  sentry_client_GoGQuZo4Et
]