<template>
  <NuxtLoadingIndicator />
  <NuxtPage />
  <ClientOnly>
    <YandexMetrika />
  </ClientOnly>
</template>


<script setup>
useHead({
  meta: [{
    name: "robots",
    content: "noindex, nofollow"
  }]
})
</script>