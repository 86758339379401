/** Стор для хранения данных JWT-токена */
export const useJwtStore = defineStore('jwt-store', {
  state: () => ({
    token: null,
    refreshToken: null,
    expiredAt: null,
  }),
  actions: {
    /**
     * Задает новый токен.
     * @param {string} token - токен.
     */
    setToken(token) {
      this.token = token;
      this.expiredAt = this.decode(token).expired_at;
      useCookie('BountyToken', { maxAge: 2147483647, httpOnly: true }).value = token;
    },
    /**
     * Задает новый рефреш-токен.
     * @param {string} refreshToken - рефреш-токен.
     */
    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken;
      useCookie('BountyRefreshToken', { maxAge: 2147483647 }).value = refreshToken;
    },
    /**
     * Проверяет есть ли токен.
     * @returns {boolean} - да / нет.
     */
    isExists() {
      return this.refreshToken !== null;
    },
    /**
     * Проверяет протух ли токен.
     * @returns {boolean} - да / нет.
     */
    isExpired() {
      if (typeof this.expiredAt !== 'number') {
        return true;
      }
      // отнимаем 30 секунд (на всякий случай)
      return new Date() > new Date(this.expiredAt - 30);
    },
    /**
     * Декодирует токен.
     * @param {string} token - токен.
     * @returns {object} - данные из токена.
     */
    decode(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(atob(base64));
    },
    /**
     * Очищает все данные токенов.
     */
    clear() {
      this.token = null;
      this.refreshToken = null;
      this.expiredAt = null;
      useCookie('BountyToken').value = null;
      useCookie('BountyRefreshToken').value = null;
    },
    /**
     * Инициализирует значения токенов из куки.
     */
    initializeByCookie() {
      if (this.token && this.refreshJwtToken) {
        return;
      }

      if (useCookie('BountyToken').value) {
        this.token = useCookie('BountyToken').value;
        this.expiredAt = this.decode(this.token).expired_at;
      }

      if (useCookie('BountyRefreshToken').value) {
        this.refreshToken = useCookie('BountyRefreshToken').value;
      }
    },
    /**
     * Обновляет токен.
     * @returns {boolean} - успешно ли обновил.
     */
    async refresh() {
      const API = useAuthApi();
      const { data: response, error } = await API.refreshJwtToken(this.refreshToken);
      if (error.value) {
        return false;
      }

      this.setToken(response.value.token);
      this.setRefreshToken(response.value.refresh_token);

      return true;
    },
  },
});
