// Миддлвар для проверки принятия соглашения о неразглашении конф. информации
export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();

  // если не удалось загрузить данные пользователя - завершаем выполнение
  if (!userStore.personalData.hasOwnProperty('conf_info_agreement')) {
    return;
  }

  const confirmed = userStore.personalData.conf_info_agreement === true;

  // если идем на страницу с подтверждением
  if (to.name === 'confirm') {
    // если уже приняты соглашения - редиректим в каталог
    if (confirmed) {
      return navigateTo('/catalog');
    }
    // если идем на любую другую страницу
  } else {
    // если не приняты соглашения - редиректим на страницу с формой принятия соглашений
    if (!confirmed) {
      return navigateTo('/confirm');
    }
  }
});
