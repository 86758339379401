import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45auth_45global from "/app/middleware/1_auth.global.js";
import _2_45user_45global from "/app/middleware/2_user.global.js";
import _3_45confirm_45global from "/app/middleware/3_confirm.global.js";
import _4_45kind_45tree_45global from "/app/middleware/4_kind_tree.global.js";
import _5_45filters_45global from "/app/middleware/5_filters.global.js";
import _6_45favorites_45global from "/app/middleware/6_favorites.global.js";
import _7_45admin_45global from "/app/middleware/7_admin.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45auth_45global,
  _2_45user_45global,
  _3_45confirm_45global,
  _4_45kind_45tree_45global,
  _5_45filters_45global,
  _6_45favorites_45global,
  _7_45admin_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}